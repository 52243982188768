import React, { useEffect, useState } from "react";

export default function OtherNavbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [navBarTransparent, setNavbarTransparent] = useState(true)

  const handleScroll = (event) => {
    if(window.scrollY > 100)
      setNavbarTransparent(false)
    else  
      setNavbarTransparent(true)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <nav
        className={
          (navBarTransparent
            ? "top-0 fixed z-50 w-full"
            : "top-0 fixed z-50 w-full shadow-lg bg-white shadow-lg") +
          " flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
        }
        style={{ transition: "all .4s ease" }}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className={
                (navBarTransparent 
                  ? "lg:text-white lg:hover:text-green text-gray-800"
                  : "text-gray-800 hover:text-green") +
                " text-md font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase cursor-pointer"
              }
              href="https://travlee.uk"
            >
              Travlee
            </a>
          </div>
          
        </div>
      </nav>
    </>
  );
}
